import React from "react";
import logo from "../assets/logo.png";

const Footer = () => {
  return (
    <div style={{ marginTop: "25%" }}>
      <span style={{ fontSize: 20 }}>Powered by</span>
      <img width="80px" alt="" style={{ marginBottom: -10 }} src={logo} />
    </div>
  );
};

export default Footer;
