import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme, StylesProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#474546",
    },
    secondary: {
      main: "#d9534f",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StylesProvider injectFirst>
      <App />
    </StylesProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
