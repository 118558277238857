/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ArrowBackIosOutlined } from "@material-ui/icons";

const PaymentDetails = ({ provider, setScreen, order, onPay }) => {
  const classes = useStyles();

  const [phone, setPhone] = useState("");

  const [card, setCard] = useState({
    holder: "",
    number: "",
    cvv: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    if (provider.option === "mobile") {
      const _phone = order.customer.phone.replace("+", "");
      setPhone(_phone);
    }
  }, []);

  const _onChange = ({ target }) => {
    const { value, name } = target;
    setCard((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const Pay = async () => {
    if (phone) {
      const data = {
        phone,
        order_uuid: order.order_uuid,
      };

      if (provider.payment_mode_code) {
        data.payment_mode_code = provider.payment_mode_code;
        data.payment_option_code = provider.payment_option_code;
      } else {
        data.payer_mode_id = provider.payer_mode_id;
      }

      onPay(data);
    }
  };

  return (
    <div>
      <div className={classes.back} onClick={setScreen}>
        <div className={classes.back_icon}>
          <ArrowBackIosOutlined
            style={{ fontSize: 15, padding: "2px 4px", marginBottom: -2 }}
          />
        </div>
        <div style={{ paddingTop: "2%", marginLeft: "5%" }}>Back</div>
      </div>
      {provider.option === "card" && <div style={{ marginTop: 20 }} />}
      <div className={classes.fields}>
        {provider.option === "mobile" && (
          <>
            <div className={classes.label}>
              Enter your{" "}
              <span style={{ fontWeight: "bold" }}>{provider.name}</span> number
            </div>
            <TextField
              variant="outlined"
              size="small"
              value={phone}
              className={classes.phone}
              placeholder="072*******"
              onChange={({ target }) => setPhone(target.value)}
            />
          </>
        )}
        {provider.option === "card" && (
          <>
            <div>Card Holder</div>
            <TextField
              variant="outlined"
              size="small"
              value={card.holder}
              name="holder"
              onChange={_onChange}
            />
            <div>Card Number</div>
            <TextField
              variant="outlined"
              size="small"
              value={card.number}
              name="number"
              onChange={_onChange}
            />
            <div>CVV</div>
            <TextField
              variant="outlined"
              size="small"
              value={card.cvv}
              name="cvv"
              onChange={_onChange}
            />
            <div className={classes.expiry_date}>
              <div>Expiry Date</div>
              <div>
                <TextField
                  variant="outlined"
                  size="small"
                  value={card.month}
                  name="month"
                  onChange={_onChange}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  value={card.year}
                  name="year"
                  onChange={_onChange}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Button fullWidth className={classes.pay_btn} onClick={Pay}>
        Pay {order.currency} {order.order_total_price}
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  pay_btn: {
    backgroundColor: "#ff0000",
    borderRadius: 15,
    marginTop: "5%",
    textTransform: "none",
    color: "#ffff",
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "#ff0000",
    },
  },

  back: {
    display: "flex",
    borderBottom: "solid thin #000",
    fontWeight: "bold",
  },

  back_icon: {
    border: "solid thin #000",
    borderRadius: 15,
  },

  phone: {
    width: "100%",
  },

  fields: {
    marginTop: "15%",
  },

  label: {
    textAlign: "start",
    marginBottom: 5,
    paddingLeft: "3%",
  },

  expiry_date: {},
}));

export default PaymentDetails;
