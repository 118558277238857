import { Button, makeStyles } from "@material-ui/core";
import React from "react";

const Error = ({ error, onRetry }) => {
  const classes = useStyles();

  return (
    <div>
      <div>
        {error.results?.requestStatusDescription ||
          error.status?.statusDescription ||
          error.msg}
      </div>
      <Button fullWidth className={classes.retry_btn} onClick={onRetry}>
        Retry
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  retry_btn: {
    backgroundColor: "#ff0000",
    borderRadius: 15,
    marginTop: "10%",
    padding: "4px 8px",
    textTransform: "none",
    color: "#ffff",
    "&:hover": {
      backgroundColor: "#ff0000",
    },
  },
}));

export default Error;
