/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./App.css";
import SelectProvider from "./components/select_provider";
import PaymentDetails from "./components/payment_details";
import Footer from "./components/footer";
import Error from "./components/error";
import Loader from "react-loader-spinner";
import params from "./utils";
import { get, post } from "./utils/fetch";
import { BASE_URL } from "./app.config";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Success from "./components/success";

function App() {
  const classes = useStyles();
  const [screen, setScreen] = useState("main");
  const [loading, setLoading] = useState(true);

  const [cancel_loading, setCancelLoading] = useState(false);
  const [providers, setProviders] = useState([]);

  const [provider, setProvider] = useState();
  const [order, setOrder] = useState();
  const [error, setError] = useState("");

  const [proceed, setProceed] = useState(false);
  const [check_status, setCheckStatus] = useState(false);
  const [status_count, setStatusCount] = useState(1);

  const getProviders = async (uuid) => {
    const order = await get(`${BASE_URL}/cart_order/${uuid}`);

    if (order.complex_uuid) {
      setOrder(order);

      if (order.payment) {
        let { id } = order.payment;

        if (order.country_code === "GH") {
          if (id !== "71") {
            id = "71";
          }
        }

        const payment = await get(
          `${BASE_URL}/complexes/${order.complex_uuid}/get_payment/${id}`
        );

        setLoading(false);

        if (payment) {
          if (payment.providers) {
            setProviders(payment.providers);
            setLoading(false);
          } else {
            setLoading(false);

            await fetch("https://cloud.dial-a-delivery.online/api/logger", {
              method: "POST",
              mode: "cors",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                payload: {
                  order,
                  payment_id: id,
                },
                _function: "custom_api",
                operation: "get_providers",
                key: uuid,
              }),
            });
          }
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    const { uuid } = params;

    if (uuid) {
      getProviders(uuid);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (check_status) {
      const interval = setTimeout(() => {
        setStatusCount(status_count + 1);
        checkPayStatus(status_count + 1);
        setCheckStatus(false);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [check_status]);

  const checkPayStatus = async (retryCount) => {
    let msg = {
      results: {
        requestStatusDescription: "Payment failed, please try again later",
      },
    };

    if (provider.payment_mode_code) {
      const url = `${BASE_URL}/cellulant/v3/get_payment_status`;

      const data = {
        merchant_transaction_id: proceed.results.merchant_transaction_id,
        service_code: provider.service_code,
        token: proceed.token,
        api_key: proceed.api_key,
      };

      const res = await post(url, data);

      if (res.results) {
        if (
          res.results.request_status_code === 178 ||
          res.results.request_status_code === 183
        ) {
          setScreen("success");
          setLoading(false);
        } else {
          if (res.results) {
            if (res.results.failed_payments) {
              const fPl = res.results.failed_payments.length;

              const fPayment = res.results.failed_payments[fPl - 1];

              if (fPayment) {
                if (fPayment.payer_narration) {
                  msg.results.requestStatusDescription =
                    fPayment.payer_narration;
                }
              }
            }
          }

          if (retryCount > 60) {
            if (res.status.status_code === 200) {
              msg.results.requestStatusDescription =
                "Payment timed out, please try again.";

              setError(msg);
            } else {
              res.results.requestStatusDescription =
                res.results.request_status_description;

              setError(res);
            }

            setScreen("error");
            setLoading(false);
          } else {
            setCheckStatus(true);
          }
        }
      } else {
        setScreen("error");
        setLoading(false);
        setError(msg);
      }
    } else {
      const url = `${BASE_URL}/cellulant/get_payment_status`;

      const data = {
        merchantTransactionID: proceed.results.merchantTransactionID,
        serviceCode: provider.service_code,
        checkoutRequestID: proceed.results.checkoutRequestID,
        token: proceed.token,
      };

      const res = await post(url, data);

      if (res.results) {
        if (
          res.results.requestStatusCode === 178 ||
          res.results.requestStatusCode === 183
        ) {
          setScreen("success");
          setLoading(false);
        } else {
          if (res.results) {
            if (res.results.failedPayments) {
              const fPl = res.results.failedPayments.length;

              const fPayment = res.results.failedPayments[fPl - 1];

              if (fPayment) {
                if (fPayment.payerNarration) {
                  msg.results.requestStatusDescription =
                    fPayment.payerNarration;
                }
              }
            }
          }

          if (retryCount > 60) {
            setScreen("error");
            setLoading(false);

            if (res) {
              if (res.status) {
                if (res.status.statusCode === 200) {
                  setError(msg);
                } else {
                  setError(res);
                }
              } else {
                setError(msg);
              }
            } else {
              setError(msg);
            }
          } else {
            setCheckStatus(true);
          }
        }
      } else {
        setScreen("error");
        setLoading(false);
        setError(msg);
      }
    }
  };

  const onPay = async (data) => {
    setLoading(true);
    let url;

    if (provider.payment_mode_code) {
      url = `${BASE_URL}/cellulant/v3/process_payment`;
    } else {
      url = `${BASE_URL}/cellulant/process_payment`;
    }

    const res = await post(url, data);

    if (res.status) {
      if (res.status.statusCode === 200) {
        setProceed(res);
        setCheckStatus(true);
      } else {
        setScreen("error");
        setError(res);
        setLoading(false);
      }
    } else {
      let msg = {
        results: {
          requestStatusDescription: "Payment failed, please try again later",
        },
      };

      if (res.errorMessage) {
        msg.results.requestStatusDescription = res.errorMessage;
      }

      setScreen("error");
      setError(msg);
      setLoading(false);
    }
  };

  const onCancel = async () => {
    setCancelLoading(true);
    const { uuid } = params;

    await fetch(
      "https://cloud.dial-a-delivery.online/api/order/remove_pending_order",
      {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          order_uuid: uuid,
          phone: order.customer.phone,
        }),
      }
    );

    window.location.replace(
      "https://cloud.dial-a-delivery.online/webview/cancel"
    );

    setCancelLoading(false);
  };

  const view = () => {
    let _screen;
    if (screen === "main") {
      _screen = (
        <SelectProvider
          screen={screen}
          setProvider={(_provider) => {
            setScreen(_provider.name);
            setProvider(_provider);
          }}
          providers={providers}
        />
      );
    } else if (screen === "error") {
      _screen = <Error error={error} onRetry={() => setScreen("main")} />;
    } else if (screen === "success") {
      _screen = (
        <Success msg={`Paid ${order.currency}${order.order_total_price}`} />
      );
    } else {
      _screen = (
        <PaymentDetails
          screen={screen}
          setScreen={() => setScreen("main")}
          provider={provider}
          order={order}
          onPay={onPay}
        />
      );
    }
    return _screen;
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <div>
          <div style={{ marginBottom: 30 }}>
            <Loader type="Oval" color="red" width="30" height="30" />
          </div>
          {proceed && (
            <div
              style={{ marginTop: "10%", textAlign: "center" }}
              dangerouslySetInnerHTML={{
                __html: `${proceed.results.paymentInstructions}`,
              }}
            />
          )}
        </div>
      ) : (
        <div>
          {view()}

          {screen !== "success" && (
            <Button fullWidth className={classes.cancel_btn} onClick={onCancel}>
              {cancel_loading ? "Cancelling" : "Cancel"}
            </Button>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      margin: "5% 8%",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "5% 30%",
    },
  },

  cancel_btn: {
    backgroundColor: "#767a7d",
    borderRadius: 15,
    marginTop: "3%",
    padding: "4px 8px",
    textTransform: "none",
    color: "#ffff",
    "&:hover": {
      backgroundColor: "#767a7d",
    },
  },
}));

export default App;
