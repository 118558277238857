import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const SelectProvider = ({ setProvider, providers }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.select_provider}>Select provider</div>

      {providers.map((provider, index) => (
        <div
          className={classes.provider}
          key={index}
          onClick={() => setProvider(provider)}
        >
          <img width="50px" alt={provider.name} src={provider.logo_url.en} />
        </div>
      ))}
      {providers.length === 0 && (
        <div>No providers available for this complex</div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  select_provider: {
    marginBottom: "20%",
    marginTop: 10,
    fontWeight: "bold",
    fontSize: 20,
  },

  provider: {
    border: "solid grey thin",
    borderRadius: 20,
    padding: "8px 0",
    marginTop: 20,
  },
}));

export default SelectProvider;
