export const post = async (url, data) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  });
  let result = await response.json();
  return result;
};

export const put = async (url, data) => {
  let response = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  });
  let result = await response.json();
  return result;
};

export const get = async (url) => {
  let response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  });
  let result = await response.json();
  return result;
};
