import React, { useEffect } from "react";
import icon_check from "../assets/icon_check.png";

const Success = ({ msg }) => {
  useEffect(() => {
    const interval = setTimeout(() => {
      const url = "https://cloud.dial-a-delivery.online/webview/success";
      window.location.replace(url);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <img src={icon_check} alt="" />
      </div>
      <div style={{ fontWeight: "bold", fontSize: "large" }}>
        Payment Successfull
      </div>
      <div>{msg}</div>
    </div>
  );
};

export default Success;
